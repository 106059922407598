import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';

import { CustomloaderComponent } from './loader/customloader/customloader.component';
import { SecurityService } from './services/security.service';
import { DialogService } from './shared/dialog/dailog.service';
import { UtilityService } from './shared/services/utility.service';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  public customLoader = CustomloaderComponent;
  public spinkit = Spinkit;
  hasAccess = false;
  accessCheck = false;
  @Output() notifCount = new EventEmitter();
  @HostBinding('class') public cssClass = 'DefaultClass';
  constructor(private router: Router, private dialogService: DialogService, public utilityService: UtilityService,
              private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
              private securityService: SecurityService) {
    let tenant = 'tkeuat';
    let hostname = window.location.hostname.split('.')[0];
    if (hostname !== 'localhost') {
      tenant = hostname.replace(/-/g, '');
    }

    window.sessionStorage.setItem('tenant', tenant);
    this.utilityService.setTenant();

    this.router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        hostname = window.location.hostname.split('.')[0];
        if (hostname !== 'localhost') {
          tenant = hostname.replace(/-/g, '');
        }
        window.sessionStorage.setItem('tenant', tenant);
        this.dialogService.dialog.closeAll();
      }
      // tslint:disable-next-line:  no-collapsible-if
      if (event instanceof NavigationEnd) {
        if (this.utilityService.IsStaffNumberSet() && this.utilityService.checkNotification && !this.securityService.dontNotify) {
          this.utilityService.checkNotification = false;
          setTimeout(() => {
            this.utilityService.checkNotification = true;
            this.utilityService.checkRoles();
            if ((this.utilityService.isEmployee || this.utilityService.isHelpdeskManager)) {
                this.notifCount.emit(this.utilityService.notificationCount());
              }
            }, 120000);
        }
      }

    });
    this.matIconRegistry.addSvgIcon(
      'icon-theme',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icon-theme.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'help_icon_round',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/help-round-ico.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'raise_ticket',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/tickets.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_payslip',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icon_payslip.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'current_payslip',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/currentPayslip-ico.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'current_payslip_hover',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/currentPayslip_hover-ico.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon_notification',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icon_notification.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logout_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icon_logout.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cr_filter_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/control_reports_filter_ico.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'average_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/smile/average-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'good_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/smile/good-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'poor_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/smile/poor-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'announcement_blue',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/announcement-blue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'announcement_green',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/announcement-green.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'announcement_line',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/announcement-line.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'announcement_orange',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/announcement-orange.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'announcement_red',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/announcement-red.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'payslip_new',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/payslip_new.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'myaccount',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/myaccount.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'reminder_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/reminder.svg')
    );
  }

  ngOnInit() {
    this.securityService.checkSubscriptionStatus().subscribe(res => {
      this.hasAccess = res.hasSubscription && res.isActive;
      this.accessCheck = true;
    });
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((_event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
      this.cssClass = 'DefaultClass';
    });
  }
}
