<style>
  .fddiv {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      background: url(/assets/img/body_bg.webp) no-repeat center center / cover !important;
  }

  .Ttext {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px;
      margin-top: 20px;
  }

  .loginH {
      background-color: #fff;
      align-self: center;
      box-shadow: 2px 4px 12px 4px rgba(0, 0, 0, 0.37);
      padding: 50px 80px;
      text-align: center;
  }
  .ml10{
    margin-left: 10px;
  }

</style>
<div class="fddiv">
  <div class="loginH">
      <div><img alt="direct" height="60" src="assets/img/directimage.png" class="ml10"></div>
      <div class="Ttext">Hang Tight !</div>
      <p>You're being directed to Reganto it may take up to 10 seconds</p>
      <div><img alt="RegantoLogo" height="60" class="pb4 align-bottom" src="assets/img/RegantoLogo_footer.svg"></div>
  </div>
</div>