import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CalendarModule } from 'angular-calendar';
import { ChartModule } from 'angular-highcharts';
import { FullCalendarModule } from 'ng-fullcalendar';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import {
  EmployeeDocumentDisplayComponent,
} from 'src/app/employee/employee-document-display/employee-document-display.component';

import { WizardComponent } from './activation/wizard/wizard.component';
import { AppMaterialModule } from './app-material.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AuthGuard } from './auth.guard';
import { DocumentDetailsComponent } from './employee/document-details/document-details.component';
import { EditEventDailogComponent } from './help/calendar-timeline/edit-event-dailog/edit-event-dailog.component';
import { HasClaimDirective } from './Interfaces/security/has-claim.directive';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthInterceptor } from './loader/auth-interceptor';
import { CustomloaderComponent } from './loader/customloader/customloader.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { ActionsComponent } from './shared/actions/actions.component';
import { CustomDatePickerComponent } from './shared/components/generic/custom-date-picker/custom-date-picker.component';
import { ImageCropperComponent } from './shared/components/generic/image-cropper/image-cropper.component';
import { CancelDialogComponent } from './shared/dialog/cancel-dialog/cancel-dialog.component';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './shared/dialog/error-dialog/error-dialog.component';
import { SucessDialogComponent } from './shared/dialog/sucess-dialog/sucess-dialog.component';
import { NavbarModule } from './shared/navbar/navbar.module';
import { DateAgoPipe } from './shared/pipes/date-ago.pipe';
import { IndianNumberFormatPipe } from './shared/pipes/indian-number-format.pipe';
import { SafeHtmlPipe } from './shared/pipes/SafeHtml.pipe';
import { CustomPreloadingStrategyService } from './shared/services/custom-preloading-strategy.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { SidebarModule } from './sidebar/sidebar.module';
import { SamlHandlerComponent } from './saml-handler/saml-handler.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  exports: [
    AppMaterialModule,
  ],
  declarations: [SamlHandlerComponent],
  providers: [AuthGuard]
})
export class MaterialModule { }
@NgModule({
  exports: [
    AppMaterialModule,
    CalendarModule,
    ChartModule,
    ExcelModule,
    FullCalendarModule,
    GridModule,
    PerfectScrollbarModule,
    PDFExportModule,
    PdfViewerModule,
    PDFModule
  ],
  imports: [
    CalendarModule.forRoot(),
    ChartModule,
    ExcelModule,
    FullCalendarModule,
    GridModule,
    PerfectScrollbarModule,
    PDFExportModule,
    PdfViewerModule,
    PDFModule,
  ],
  declarations: [],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }]
})
export class CommonExternalModule { }

@NgModule({
  exports: [
    ActionsComponent,

    DateAgoPipe,
    IndianNumberFormatPipe,
    SafeHtmlPipe,

    CommonExternalModule
  ],
  imports: [
    CommonExternalModule
  ],
  declarations: [
    ActionsComponent,
    EmployeeDocumentDisplayComponent,
    EditEventDailogComponent,
    ConfirmDialogComponent,
    SucessDialogComponent,
    CancelDialogComponent,
    ErrorDialogComponent,

    DateAgoPipe,
    IndianNumberFormatPipe,
    SafeHtmlPipe
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SucessDialogComponent,
    CancelDialogComponent,
    ErrorDialogComponent,
    EmployeeDocumentDisplayComponent,
    EditEventDailogComponent
  ]
})
export class CommonComponentsModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: CustomPreloadingStrategyService }),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    MaterialModule,
    SidebarModule,
    NavbarModule,
    NgxMaskModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    CommonComponentsModule,
    ImageCropperModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    WizardComponent,
    HasClaimDirective,
    DocumentDetailsComponent,
    CustomloaderComponent,
    AccessDeniedComponent
  ],
  entryComponents: [
    CustomloaderComponent,
    ImageCropperComponent,
    AccessDeniedComponent,
    CustomDatePickerComponent

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
 }
