import { TitleCasePipe } from '@angular/common';
import { AfterViewChecked, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NavigationStart, Router } from '@angular/router';
import { drawDOM, exportPDF, Group } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import * as FileSaver from 'file-saver';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { NumberToWordsPipe } from 'src/app/shared/pipes/number-to-words.pipe';

import { environment } from '../../../environments/environment';
import { DocumentDataParsingService } from '../../shared/services/documentDataParsing.service';
import { FormatterService } from '../../shared/services/formatter.service';
import { IconService } from '../../shared/services/svg-icon-service';

@Component({
  selector: 'app-employee-document-display',
  templateUrl: './employee-document-display.component.html',
  styleUrls: ['./employee-document-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DocumentDataParsingService, NumberToWordsPipe, TitleCasePipe]
})
export class EmployeeDocumentDisplayComponent implements OnInit, AfterViewChecked, OnDestroy {
  documentData: any;
  documentDisplayMappingData: any[];
  template: string;
  type: string;
  isPdf = false;
  pdfUrl: any;
  currency: string;
  isSlipTimeAvailable: boolean;
  currentDocumentElement: any;
  weekDataids : any[] = [
    {
      id:'week1headersectiona',
      value: 'hsa',
      section: 'a',
      col: 0
    },
    {
      id:'week1sectiona',
      value: 'w1sa',
      section: 'a',
      col: 1
    },
    {
      id:'week2sectiona',
      value: 'w2sa',
      section: 'a',
      col: 1
    },
    {
      id:'week3sectiona',
      value: 'w3sa',
      section: 'a',
      col: 1
    },
    {
      id:'week4sectiona',
      value: 'w4sa',
      section: 'a',
      col: 1
    },
    {
      id:'week5sectiona',
      value: 'w5sa',
      section: 'a',
      col: 1
    },
    {
      id:'totalssectiona',
      value: 'tsa',
      section: 'a',
      col: 1
    },
    {
      id:'week1headersectionb',
      value: 'hsb',
      section: 'b',
      col: 0
    },
    {
      id:'week1sectionb',
      value: 'w1sb',
      section: 'b',
      col: 1
    },
    {
      id:'week2sectionb',
      value: 'w2sb',
      section: 'b',
      col: 1
    },
    {
      id:'week3sectionb',
      value: 'w3sb',
      section: 'b',
      col: 1
    },
    {
      id:'week4sectionb',
      value: 'w4sb',
      section: 'b',
      col: 1
    },
    {
      id:'week5sectionb',
      value: 'w5sb',
      section: 'b',
      col: 1
    },
    {
      id:'totalssectionb',
      value: 'tsb',
      section: 'b',
      col: 1
    },
    {
      id:'week1headersectionc',
      value: 'hsc',
      section: 'c',
      col: 0
    },
    {
      id:'week1sectionc',
      value: 'w1sc',
      section: 'c',
      col: 1
    },
    {
      id:'week2sectionc',
      value: 'w2sc',
      section: 'c',
      col: 1
    },
    {
      id:'week3sectionc',
      value: 'w3sc',
      section: 'c',
      col: 1
    },
    {
      id:'week4sectionc',
      value: 'w4sc',
      section: 'c',
      col: 1
    },
    {
      id:'week5sectionc',
      value: 'w5sc',
      section: 'c',
      col: 1
    },
    {
      id:'totalssectionc',
      value: 'tsc',
      section: 'c',
      col: 1
    }
  ];

  @ViewChild('perfectScroll', { static: false }) perfectScroll: PerfectScrollbarComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EmployeeDocumentDisplayComponent>,
              private iconService: IconService,
              router: Router,
              private documentDataParsingService: DocumentDataParsingService, public formatterService: FormatterService,
              private numberToWordsPipe: NumberToWordsPipe,
              private titlecasePipe: TitleCasePipe) {
    router.events.subscribe(val => {
      if (val instanceof NavigationStart) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('payslippopupopen');
        this.dialogRef.close();
      }
    });
    this.iconService.init();
    const documentData = Object.assign({}, this.data.documentData);
    this.type = this.data.type;
    if (this.type.toLowerCase() === 'p60' && documentData.taxyear === '2020-2021') {
      documentData.taxyear = '2020 to 2021';
    }
    this.documentData = Object.assign({}, documentData);
    this.documentDisplayMappingData = Object.assign([], this.data.documentFiledDisplayData);
    this.pdfUrl = this.data.documentUrl;
    this.template = this.pdfUrl ? '' : this.data.template;
    this.currency = this.data.currency;
  }

  ngOnInit() {
    this.dialogRef.updateSize('90%', '95%');
    this.createDocument();
    if (this.pdfUrl) {
      this.isPdf = true;
    } else { this.isPdf = false; }
  }

  ngAfterViewChecked() {
    this.perfectScroll.directiveRef.update();
  }

  ngOnDestroy() {
    document.getElementById('pdf').remove();
  }

  exportToPdf() {
    const fileName = `${this.type}-${new Date().toDateString()}.pdf`;

    if (this.pdfUrl) {
      FileSaver.saveAs(this.pdfUrl, fileName);
    } else {
      const url = environment['baseSharedEndpoint'] + 'Shared/export';
      saveAs(this.currentDocumentElement, fileName, {
        proxyURL: url
      });
    }
  }

  createDocument() {
    const documentElement = document.createElement('div'); // is a node
    if (this.documentData.hasOwnProperty('netpay_p') && this.documentData.hasOwnProperty('netpayinwords')) {
      this.documentData['netpayinwords'] = this.titlecasePipe.transform(
        this.numberToWordsPipe.transform(Math.trunc(parseFloat(this.documentData['netpay_p']))).toUpperCase());
    }
    let data = { documentData: this.documentData, template: this.template };
    data = this.documentDataParsingService.bindValuestoHtml(data, this.type, this.currency, this.formatterService);
    this.documentData = data.documentData;
    this.template = data.template;
    this.template = this.template ? this.template.replace(new RegExp('##(.*?)##', 'gi'), '') : '';
    this.template = this.isPdf ? '' : this.template;

    documentElement.innerHTML = this.template;
    documentElement.setAttribute('id', 'templatecontent');
    document.getElementById('pdf').appendChild(documentElement);
    if (this.type.toLowerCase() === 'payslip') {

      this.isSlipTimeAvailable = document.getElementById('sliptimedata') != null;

      if (document.getElementById('earnings') != null) {
        const earningsTable = document.getElementById('earnings').getElementsByTagName('table');
        if (this.documentData['earnings'] != null) {
          document.getElementById('earnings').appendChild(this.buildTable(this.documentData, earningsTable[0], 'earnings'));
        }
      }

      if (document.getElementById('deductions') != null) {
        const deductionsTable = document.getElementById('deductions').getElementsByTagName('table');
        if (deductionsTable != null && this.documentData['deductions'] != null) {
          document.getElementById('deductions').appendChild(this.buildTable(this.documentData, deductionsTable[0], 'deductions'));
        }
      }

      if (document.getElementById('previousEmployment') != null) {
        const previousEmploymentTable = document.getElementById('previousEmployment').getElementsByTagName('table');
        if (previousEmploymentTable != null && this.documentData['previousemployment'] != null) {
          document.getElementById('previousEmployment')
            .appendChild(this.buildTable(this.documentData, previousEmploymentTable[0], 'previousemployment'));
        }
      }

      if (document.getElementById('currentEmployment') != null) {
        const currentEmploymentTable = document.getElementById('currentEmployment').getElementsByTagName('table');
        if (currentEmploymentTable != null && this.documentData['currentemployment'] != null) {
          document.getElementById('currentEmployment')
            .appendChild(this.buildTable(this.documentData, currentEmploymentTable[0], 'currentemployment'));
        }
      }

      if (document.getElementById('ytdCumulatives') != null) {
        const ytdCumulativesTable = document.getElementById('ytdCumulatives').getElementsByTagName('table');
        if (ytdCumulativesTable != null && this.documentData['ytdcumulatives'] != null) {
          document.getElementById('ytdCumulatives')
            .appendChild(this.buildTable(this.documentData, ytdCumulativesTable[0], 'ytdcumulatives'));
        }
      }

      if (document.getElementById('sliptimedata') != null) {
        const sliptimedataTable = document.getElementById('sliptimedata').getElementsByTagName('table');
        if (sliptimedataTable != null && this.documentData['sliptimedata'] != null) {
          document.getElementById('sliptimedata')
            .appendChild(this.buildTable(this.documentData, sliptimedataTable[0], 'sliptimedata'));
        }
      }

      if (document.getElementById('timesheetcontent') != null){
        this.weekDataids.forEach(weekdata => {
          let id = weekdata.id;
          let value = weekdata.value;
          let section = weekdata.section;
          let col = weekdata.col;
          let seclength = 0;
  
          if(section === 'a' ){
            seclength = this.documentData['w1sa']['components'].length;
          }
  
          if(section === 'b' ){
            seclength = this.documentData['w1sb']['components'].length;
          }
  
          if(section === 'c' ){
            seclength = this.documentData['w1sc']['components'].length;
          }
  
          if (document.getElementById(id) != null) {
            const sliptimedataTable = document.getElementById(id).getElementsByTagName('table');
            if (sliptimedataTable != null && this.documentData[value] != null) {
              document.getElementById(id)
                .appendChild(this.buildWeekDataTable(this.documentData, sliptimedataTable[0], value, col, section, seclength));
            }
          }
        });
      }      
    }

    if (this.type.toLowerCase() === 'taxstatement' || this.type.toLowerCase() === 'tax statement') {
      Object.keys(this.documentData).forEach(element => {
        if (element.includes('_table')) {
          const feildString: string = element.toString().split('_')[0];
          if (document.getElementById(feildString) != null) {
            const table = document.getElementById(feildString).getElementsByTagName('table');
            if (this.documentData[element] != null) {
              document.getElementById(feildString).appendChild(this.buildTaxTable(this.documentData, table[0], element));
            }
          }
        }
      });
    }

    if (this.type.toLocaleLowerCase() === 'appointmentletter' || this.type.toLocaleLowerCase() === 'appointment letter' ||
      this.type.toLocaleLowerCase() === 'revised ctc' || this.type.toLocaleLowerCase() === 'revisedctc') {
      const ctcbreakup = (document.getElementById('d-table')) ? document.getElementById('d-table').getElementsByTagName('table') : null;
      if (ctcbreakup != null && this.documentData['ctcbreakup'] != null) {
        document.getElementById('d-table')
          .appendChild(this.buildCTCBreakUpTable(this.documentData, ctcbreakup[0], 'ctcbreakup'));
      }
    }

    if (!this.isPdf) {
      this.currentDocumentElement = null;
      const element = document.getElementById('templatecontent');
      drawDOM(element, {
        paperSize: 'A4',
        forcePageBreak: '.page-break',
        margin: {
          top: '1cm',
          left: '1cm',
          right: '8.5mm'
        },
        scale: { x: 0.65, y: 0.7 }
      }).then((group: Group) => {
        return exportPDF(group);
      }).then((pdfData: string) => {
        this.currentDocumentElement = pdfData;
      });
    }
  }

  changeDisplayLabel(row: any) {
    row = row.replace(new RegExp('##(.*?)##', 'gi'), '');
    let index = -1;
    if (this.documentDisplayMappingData != null && this.documentDisplayMappingData.length > 0) {
      index = this.documentDisplayMappingData.findIndex(x => x.dataCode === row);
    }
    if (index !== -1) {
      return this.documentDisplayMappingData[index].displayLabel;
    }
    return row;
  }

  getDisplayLabel(component: any, taxyear: any, taxperiod: any, payrollId: any) {
    component = component.replace(new RegExp('##(.*?)##', 'gi'), '');
    let index = -1;
    if (this.documentDisplayMappingData != null && this.documentDisplayMappingData.length > 0) {
      index = this.documentDisplayMappingData.findIndex(x => x.dataCode === component &&
        x.taxYear === taxyear && x.taxPeriod === taxperiod && x.payrollId === payrollId);

      if (index === -1) {
        index = this.documentDisplayMappingData.findIndex(x => x.dataCode === component &&
          x.taxYear === taxyear && x.taxPeriod === 0 && x.payrollId === payrollId);
      }
    }
    if (index !== -1) {
      return this.documentDisplayMappingData[index].displayLabel;
    }
    return component;
  }

  buildTable(data, table: HTMLTableElement, type: string) {
    const currency = this.currency;
    const formatterService = this.formatterService;
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');
    const headRow = document.createElement('tr');
    data[type]['rowheader'].forEach(el => {
        const th = document.createElement('th');
        el = el.toString().replace(formatterService.getFormattedValue(currency, 0, 2, true).charAt(0),
          "<ng-container class='currencySymbol'>" + formatterService.getFormattedValue(currency, 0, 2, true).charAt(0) + '</ng-container>');
        th.innerHTML = el;
        headRow.appendChild(th); 
    });
    thead.appendChild(headRow);
    table.appendChild(thead);
    let tableLenght = 0;
    if (type === 'earnings' || type === 'deductions' || type === 'ytdcumulatives') {
      tableLenght = Math.max(data['earnings'] != null ? data['earnings']['components'].length : 0,
        data['deductions'] != null ? data['deductions']['components'].length : 0,
        document.getElementById('ytdCumulatives') != null && data['ytdcumulatives'] != null ?
          data['ytdcumulatives']['components'].length : 0);

      if (tableLenght >= 10) {
        tableLenght = ++tableLenght;
        // tslint:disable-next-line: no-collapsible-if
      } else {
        if (this.isSlipTimeAvailable) {
          tableLenght = tableLenght + 2;
        } else {
          tableLenght = tableLenght + 4;
        }
      }
    }

    let counter = 0;
    const taxYear = data['taxyear'];
    const taxperiod = data['taxperiod'];
    const payrollId = data['payrollid'];
    data[type]['components'].forEach(component => {
      ++counter;
      const tr = document.createElement('tr');
      let td = document.createElement('td');
        const componentDisplayLabel = this.getDisplayLabel(component, taxYear, taxperiod, payrollId);
        td.appendChild(document.createTextNode(componentDisplayLabel));
        tr.appendChild(td);
      data[type]['rowheader'].forEach((header, index) => {
        if (index !== 0) {
          td = document.createElement('td');
          if (data[type]['values'][component][header] == null) {
            td.appendChild(document.createTextNode('\u00A0'));
            // tslint:disable-next-line: no-collapsible-if
          } else {
            if (type === 'sliptimedata' && (index === 1 || index === 2)) {
              td.appendChild(document.createTextNode(data[type]['values'][component][header]));
            } else {
              td.appendChild(document.createTextNode(this.formatterService.getFormattedValue(
                this.currency, Number(data[type]['values'][component][header]), 2, false)));
            }
          }

          tr.appendChild(td);
        }
      });

      tbody.appendChild(tr);
    });

    if (counter < tableLenght && (type === 'earnings' || type === 'deductions' || type === 'ytdcumulatives')) {
      for (let index = counter; index < tableLenght; index++) {
        const tr = document.createElement('tr');
        data[type]['rowheader'].forEach(() => {
          const td = document.createElement('td');
          td.appendChild(document.createTextNode('\u00A0'));
          tr.appendChild(td);
        });
        tbody.appendChild(tr);
      }
    }
    table.appendChild(tbody);
    return table;
  }


  buildWeekDataTable(data, table: HTMLTableElement, type: string, col: number, section: string, secLength: number) {
    const currency = this.currency;
    const formatterService = this.formatterService;
    const thead = document.createElement('thead');
    thead.className = 'timesheetsub-header-row';
    const tbody = document.createElement('tbody');
    const headRow = document.createElement('tr');
    const taxYear = data['taxyear'];
    const taxperiod = data['taxperiod'];
    const payrollId = data['payrollid'];

    //0 spicies that only descriptions should be appended in the table.    
    if(col === 0) {
      data[type]['components'].forEach(component => {
        const tr = document.createElement('tr');
        let td = document.createElement('td');      
        data[type]['rowheader'].forEach((header, index) => {
          if(index !== 0){
            td = document.createElement('td');
            if (data[type]['values'][component][header] == null) {
              td.appendChild(document.createTextNode('\u00A0'));
            } 
            else if(index === 1){
              const componentDisplayLabel = this.getDisplayLabel(component, taxYear, taxperiod, payrollId);
              td.appendChild(document.createTextNode(componentDisplayLabel));
            }
            else {
              td.appendChild(document.createTextNode(this.formatterService.getFormattedValue(
                this.currency, Number(data[type]['values'][component][header]), 2, false)));
            }   
            tr.appendChild(td);
          }
        });
        tbody.appendChild(tr);
      });
      table.appendChild(tbody);
      return table;      
    }

    if(section === 'a'){
      //Append header row to the table element.
      data[type]['rowheader'].forEach((el, index) => {
        //Dont add column 1 to the table. Skip it.
        if(index !== 0){
          const th = document.createElement('th');
          el = el.toString().replace(formatterService.getFormattedValue(currency, 0, 2, true).charAt(0),
            "<ng-container class='currencySymbol'>" + formatterService.getFormattedValue(currency, 0, 2, true).charAt(0) + '</ng-container>');
          th.innerHTML = el;
          headRow.appendChild(th);
        }   
      });

      thead.appendChild(headRow);
      table.appendChild(thead);
    }    

    //If there is no data avalable create empty rows.
    if(data[type]['components'].length === 0){
      for(var i = 0; i < secLength; i++){
        const tr = document.createElement('tr');
        
        data[type]['rowheader'].forEach((header, index) => {
          header = null;
          console.log(header);
          if(index !== 0){
            let td = document.createElement('td');
            td.appendChild(document.createTextNode('\u00A0'));
            tr.appendChild(td);
          }
        });
  
        tbody.appendChild(tr);  
      }
    }
    else {
      //Append data row to the table element.
      data[type]['components'].forEach(component => {
        const tr = document.createElement('tr');
        let td = document.createElement('td');      
        data[type]['rowheader'].forEach((header, index) => {
          if(index !== 0 ){
            td = document.createElement('td');
            if (data[type]['values'][component][header] == null) {
              td.appendChild(document.createTextNode('\u00A0'));
            } 
            else {
                td.appendChild(document.createTextNode(this.formatterService.getFormattedValue(
                this.currency, Number(data[type]['values'][component][header]), 2, false)));
            }
            tr.appendChild(td);
          }
        });
        tbody.appendChild(tr);
      });
    }
    table.appendChild(tbody);
    return table;
  }

  buildTaxTable(data, table: HTMLTableElement, type: string) {
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');
    const headRow = document.createElement('tr');
    data[type]['rowheader'].forEach(el => {
      const th = document.createElement('th');
      th.appendChild(document.createTextNode(el));
      headRow.appendChild(th);
    });
    thead.appendChild(headRow);
    table.appendChild(thead);
    let tableLenght = 0;
    tableLenght = data[type] != null ? data[type]['components'].length : 0;
    let counter = 0;
    data[type]['components'].forEach(component => {
      ++counter;
      const tr = document.createElement('tr');
      let td = document.createElement('td');
      td.appendChild(document.createTextNode(component));
      tr.appendChild(td);
      data[type]['rowheader'].forEach((header, index) => {
        if (index !== 0) {
          td = document.createElement('td');
          if (data[type]['values'][component][header] == null) {
            td.appendChild(document.createTextNode('\u00A0'));
          } else if (isNaN(data[type]['values'][component][header])) {
            td.appendChild(document.createTextNode(data[type]['values'][component][header]));
          } else {
            td.appendChild(document.createTextNode(
              this.formatterService.getFormattedValue(this.currency, data[type]['values'][component][header], 2, false)));
          }
          tr.appendChild(td);
        }
      });
      tbody.appendChild(tr);
    });

    if (counter < tableLenght) {
      for (let index = counter; index < tableLenght; index++) {
        const tr = document.createElement('tr');
        data[type]['rowheader'].forEach(() => {
          const td = document.createElement('td');
          td.appendChild(document.createTextNode('\u00A0'));
          tr.appendChild(td);
        });
        tbody.appendChild(tr);
      }
    }
    table.appendChild(tbody);
    return table;
  }
  closeClick() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('payslippopupopen');
    this.dialogRef.close();
    // window.location.reload();
  }
  buildCTCBreakUpTable(data, table: HTMLTableElement, type: string) {
    const tbody = document.createElement('tbody');
    data[type]['components'].forEach(component => {
      const tr = document.createElement('tr');
      let td = document.createElement('td');
      const componentDisplayLabel = this.changeDisplayLabel(component);
      td.appendChild(document.createTextNode(componentDisplayLabel));
      tr.appendChild(td);
      data[type]['rowheader'].forEach((header, index) => {
        if (index !== 0) {
          td = document.createElement('td');
          if (data[type]['values'][component][header] == null) {
            td.appendChild(document.createTextNode('\u00A0'));
          } else {
            td.appendChild(document.createTextNode(
              this.formatterService.getFormattedValue(this.currency, data[type]['values'][component][header], 2, false)));
          }

          tr.appendChild(td);
        }
      });

      tbody.appendChild(tr);
    });
    table.appendChild(tbody);
    return table;
  }
}
