import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppUser } from '../Interfaces/security/app-user';
import { AppUserAuth } from '../Interfaces/security/app-user-auth';
import { DialogService } from '../shared/dialog/dailog.service';
import { AppMessagesService } from '../shared/services/app-messages.service';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';

@Injectable({
  providedIn: 'root',
})
export class SecurityService implements OnDestroy {
  securityObject: AppUserAuth = new AppUserAuth();
  isLoggedIn = false;
  relativeURL = 'Security/';
  tenant: string = window.sessionStorage.getItem('tenant');
  secretForEncryption = '';
  dontNotify = false;
  showOSView = 'all';
  navigatedRole = '';
  navigatedUrl = '';
  selectedTheme: boolean;

  constructor(private baseService: BaseService, private dialogService: DialogService,
              public appMessagesService: AppMessagesService, private router: Router,
              private idle: Idle, private keepalive: Keepalive) {
    this.start();
    if (window.sessionStorage.getItem('securityObject')) {
      this.secretForEncryption = window.sessionStorage.getItem('patchId');
      Object.assign(this.securityObject,
        JSON.parse(CryptoJS.AES.decrypt(window.sessionStorage.getItem('securityObject'),
          this.secretForEncryption).toString(CryptoJS.enc.Utf8)));
    }
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd && !this.idle.isRunning() && this.securityObject.isAuthenticated === true
        && this.router.url !== '/') {
        this.SetupIdleWatch();
      }
    });
  }

  ValidateUserCredentials(data: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `validate/credentials/${this.tenant}`, 'baseIdentityManagementEndpoint', data);
  }

  LoginUser(entity: AppUser): Observable<AppUserAuth> {
    this.ResetSecurityObject();
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `login/${this.tenant}`, 'baseIdentityManagementEndpoint', entity)
      .pipe(
        tap(resp => {
          this.secretForEncryption = resp.patchId;
          window.sessionStorage.setItem('patchId', resp.patchId);
          Object.assign(this.securityObject, resp);
          this.selectedTheme =  resp.selectedTheme;
          window.sessionStorage.setItem('securityObject', CryptoJS.AES.encrypt(JSON.stringify(resp),
            this.secretForEncryption).toString());
        }));
  }

  AuthenticateAndLoginById(id: string): Observable<AppUserAuth> {
    this.ResetSecurityObject();
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `loginbyid/${this.tenant}/${id}`, 'baseIdentityManagementEndpoint', null)
      .pipe(
        tap(resp => {
          this.secretForEncryption = resp.patchId;
          window.sessionStorage.setItem('patchId', resp.patchId);
          Object.assign(this.securityObject, resp);
          this.selectedTheme =  resp.selectedTheme;
          window.sessionStorage.setItem('securityObject', CryptoJS.AES.encrypt(JSON.stringify(resp),
            this.secretForEncryption).toString());
        }));
  }

  UpdateSecurityObject() {
    window.sessionStorage.setItem('securityObject',
      CryptoJS.AES.encrypt(JSON.stringify(this.securityObject), this.secretForEncryption).toString());
  }

  SetupIdleWatch() {
    this.idle.setIdle(599);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(1);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.keepalive.interval(1100);
    this.RefreshAuthToken();
    this.keepalive.onPing.subscribe(() => {
      if (this.securityObject != null && this.securityObject.staffNumber != null) {
        this.RefreshAuthToken();
      }
    });

    this.idle.onTimeout.subscribe(() => {
      if (this.securityObject != null && this.securityObject.staffNumber != null) {
        this.Logout();
        this.dialogService.getSucessDialog('Logout',
          this.appMessagesService.appStaticMessages.AppLevel.SessionTimedOut, false).afterClosed().subscribe(res => {
            if (res === 0) {
              this.router.navigate([''], { queryParams: { returnUrl: this.router.url } });
              return false;
            }
          });
      }
    });

    this.idle.watch();
  }

  setTenant() {
    this.tenant = window.sessionStorage.getItem('tenant');
  }

  RefreshAuthToken() {
    if (this.securityObject != null && this.securityObject.staffNumber != null) {
      const apiUrl = this.relativeURL + `refresh/token/${this.tenant}/${this.securityObject.staffNumber}/${this.securityObject.userName}`;
      this.baseService.Execute<any>(RequestType.GET, apiUrl, 'baseIdentityManagementEndpoint', null)
        .subscribe(response => {
          if (response != null && this.securityObject.bearerToken != null) {
            this.securityObject.bearerToken = response;
            window.sessionStorage.setItem('securityObject',
              CryptoJS.AES.encrypt(JSON.stringify(this.securityObject),
                this.secretForEncryption).toString());
          } else {
            this.ResetSecurityObject();
          }
        }, _error => {
          this.ResetSecurityObject();
        });
    } else {
      this.router.navigate([''], { queryParams: { returnUrl: this.router.url } });
      return false;
    }
  }

  RefreshAuthTokenWithRole() {
    if (this.securityObject != null && this.securityObject.staffNumber != null) {
      const apiUrl = this.relativeURL + `refresh/token/${this.tenant}/${this.securityObject.staffNumber}/${this.securityObject.userName}/${this.securityObject.currentRoleId}`;
      this.baseService.Execute<any>(RequestType.GET, apiUrl, 'baseIdentityManagementEndpoint', null)
        .subscribe(response => {
          if (response != null && this.securityObject.bearerToken != null) {
            this.securityObject.bearerToken = response;
            window.sessionStorage.setItem('securityObject',
              CryptoJS.AES.encrypt(JSON.stringify(this.securityObject),
                this.secretForEncryption).toString());
          } else {
            this.ResetSecurityObject();
          }
        }, _error => {
          this.ResetSecurityObject();
        });
    } else {
      this.router.navigate([''], { queryParams: { returnUrl: this.router.url } });
      return false;
    }
  }

  Logout(): void {
    this.ResetSecurityObject();
  }

  ResetSecurityObject(): void {
    if (this.securityObject != null) {
      this.securityObject.userName = '';
      this.securityObject.bearerToken = '';
      this.securityObject.isAuthenticated = false;
      this.securityObject.claims = null;
      this.securityObject.staffNumber = null;
    }

    window.sessionStorage.removeItem('securityObject');
    window.sessionStorage.removeItem('patchId');
    window.sessionStorage.removeItem('tenant');

    if (this.idle != null) {
      this.idle.stop();
    }
  }

  hasClaim(claimType: any): boolean {
    let ret = true;

    // See if an array of values was passed in.
    if (typeof claimType === 'string') {
      ret = this.isClaimValid();
    } else {
      const claims: string[] = claimType;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid();
          // If one is successful, then let them in
          if (ret) {
            break;
          }
        }
      }
    }
    return ret;
  }

  private isClaimValid(): boolean {
    return true;
  }

  checkSubscriptionStatus(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `checkSubscription/${this.tenant}`, 'baseIdentityManagementEndpoint', null);
  }

  GetReleaseNotification(version: string, LastInstalledFeatureId: number, status?: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + `getReleaseNotifications/${this.tenant}/${version}/${this.securityObject.staffNumber}/${LastInstalledFeatureId}/Web/${status ? status : null}`, 'baseIdentityManagementEndpoint', null);
  }

  // Bind the eventListener
  private start(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage && event.key === 'isLoggedOut') {
          this.Logout();
          window.open('?returnUrl=', '_self');
    }
  }

  // Handle active listeners when onDestroy 
  private stop(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop()
  }
}
