import { Routes } from '@angular/router';

import { WizardComponent } from './activation/wizard/wizard.component';
import { DocumentDetailsComponent } from './employee/document-details/document-details.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { LockAccountComponent } from './pages/lock-account/lock-account.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SamlHandlerComponent } from './saml-handler/saml-handler.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'activation',
    component: WizardComponent
  },
  {
    path: 'samlhandler',
    component: SamlHandlerComponent
  },
  {
    path: 'details/:blockid/:documentAlias',
    component: DocumentDetailsComponent
  },
  {
    path: 'lockaccount/:staffNumber',
    component: LockAccountComponent
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
        data: { preload: true, delay: 1000 }
      },
      {
        path: 'administrator',
        loadChildren: () => import('./adminstrator/adminstrator.module').then(m => m.AdminstratorModule),
        data: { preload: true, delay: 1000 }
      },
      {
        path: 'helpDeskAdmin',
        loadChildren: () => import('./help-desk-adminstrator/help-desk-adminstrator.module').then(m => m.HelpDeskAdminstratorModule),
        data: { preload: true, delay: 1000 }
      },
      {
        path: 'helpDeskManager',
        loadChildren: () => import('./help-desk-manager/help-desk-manager.module').then(m => m.HelpDeskManagerModule),
        data: { preload: true, delay: 1000 }
      },
      {
        path: 'paymanager',
        loadChildren: () => import('./paymanager/paymanager.module').then(m => m.PaymanagerModule),
        data: { preload: true, delay: 1000 }
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'pages',
      loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    }]
  },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '/404' }
];
