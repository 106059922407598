import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SecurityService } from '../services/security.service';
import { IValidatedUserPreference } from '../Interfaces/identitymanagement/IValidatedUserPreference';
import { DialogService } from '../shared/dialog/dailog.service';
import { AppUser } from '../Interfaces/security/app-user';
import { AppUserAuth } from '../Interfaces/security/app-user-auth';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-saml-handler',
  templateUrl: './saml-handler.component.html',
  styleUrls: ['./saml-handler.component.scss']
})
export class SamlHandlerComponent implements OnInit {

  tenant: string | null = null;
  id: string | null = null;
  decodedString: string = '';
  validateUserPreference: IValidatedUserPreference;
  isSendAsSMS: boolean = false;
  user: AppUser = new AppUser();
  securityObject: AppUserAuth = null;
  slides: any[];
  slideConfig = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    useTransform: true,
    cssEase: 'ease-in-out'
  };

  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent; 
  
  constructor(
    private route: ActivatedRoute,
    private securityService: SecurityService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tenant = params['tenant'];  // Get the 'userId' query parameter
      this.id = params['id'];    // Get the 'token' query parameter
      window.sessionStorage.setItem('tenant', this.tenant);
    });

    this.authenticateAndLoginById();
  }

  // validateUser() {
  //   this.securityService.ValidateUserGuid(this.id).subscribe(
  //     response => {
  //       if (response) {
  //         this.securityService.isLoggedIn = true;
  //         window.sessionStorage.setItem('showNotifications', 'false');
  //         window.sessionStorage.setItem('showLanding', 'true');
  //         this.authenticateAndLoginById();          
  //       }
  //     },
  //     error => {
  //       if (error != null && error.error != null && error.error !== undefined && error.error.error !== null
  //         && error.error.error !== undefined) {
  //         this.dialogService
  //           .getSucessDialog('Error', error.error.error[0].message, false)
  //           .afterClosed();
  //       } else {
  //         this.dialogService
  //           .getSucessDialog('Error', error, false)
  //           .afterClosed();
  //       }
  //     }
  //   );
  // }

  authenticateAndLoginById() {
    this.securityService.AuthenticateAndLoginById(this.id).subscribe(
      resp => {
        this.securityService.isLoggedIn = true;
        window.sessionStorage.setItem('showNotifications', 'false');
        window.sessionStorage.setItem('showLanding', 'true');
        this.securityObject = resp;
        if (this.securityObject.isAuthenticated) {
          this.router.navigate(['/dashboard']);
        }
      },
      error => {
        if (error != null && error.error != null && error.error !== undefined) {
          this.dialogService
            .getSucessDialog('Error', error.error.error[0].message, false)
            .afterClosed();
        } else {
          this.dialogService
            .getSucessDialog('Error', error, false)
            .afterClosed();
        }
      }
    );
  }

}
